import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ActionBasedLearningConsulting = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ABL Brain.PNG" }) {
        childImageSharp {
          fixed(width: 415, height: 248) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Fitness and Spirit- My Story" />
      <div className="container-main">
        <div className="container-main_div">
          <h1 className="text-center">Action Based Learning Consulting</h1>
          <div>
            <Img
              fixed={data.file.childImageSharp.fixed}
              style={{
                margin: '40px auto',
                textAlign: 'center',
                display: 'block',
              }}
            />
          </div>

          <p style={{ paddingTop: '2%' }}>
            Brain science has come a long way throughout the years of research.
            It has been discovered that adding “movement with purpose”
            frequently while teaching, can help students and make the
            instructor’s day a lot easier too. We, as humans, were not made for
            sitness, we were made for fitness! The physiology of the brain and
            the body changes after a human sits in a chair for about 17 minutes.
            The brain sends a signal to the body that it is time for a nap.
          </p>
          <p>
            Our brains are not designed to maintain continuous focus. The brain
            requires processing time in order to make meaning. We all learn with
            our mind, heart and body. Through embodied cognition we can prepare
            our brains for learning. Jean Blaydes Madigan
          </p>
          <h5>“What makes us move is also what makes us Think!”</h5>
          <p>
            When implementing ABL with your students, the following benefits are
            supported through brain research:
          </p>
          <ul>
            <li>
              Neurogenesis, growth of new brain cells in the learning and memory
              center (hippocampus) of the brain.
            </li>
            <li>Brain fuel of oxygen and glucose get to the brain faster.</li>
            <li>
              Provides spatial awareness to support the brain to see letters and
              numbers on a page.
            </li>
            <li>
              Engages static and dynamic balance to put the brain and body into
              focus and attention.
            </li>
            <li>
              Crossing the midlines of the brain and body aid in coordination of
              movements and the integration of both brain hemispheres.
            </li>
            <li>Motor movements help lay the framework for learning.</li>
            <li>
              Activates BDNF, the Miracle Grow for the brain to nourish and
              protect the neural pathways for learning.
            </li>
            <li>Promotes emotional safety through positive social feedback.</li>
            <li>
              Help improve student behavior, fidgeting, attention span, focus,
              participation, and academic success.
            </li>
          </ul>
          <p>
            As an Action Based Learning Consultant, I will equip and provide you
            with an understanding of your ‘Why’ and ‘How’ to implement Action
            Based Learning within the classroom, ABL Lab, or at home.
          </p>
        </div>
      </div>
    </Layout>
  )
}
export default ActionBasedLearningConsulting
